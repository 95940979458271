import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let FinanceiroValorPagoTextComponent = class FinanceiroValorPagoTextComponent extends Vue {
};
__decorate([
    Prop({ type: Object })
], FinanceiroValorPagoTextComponent.prototype, "propItem", void 0);
FinanceiroValorPagoTextComponent = __decorate([
    Component
], FinanceiroValorPagoTextComponent);
export default FinanceiroValorPagoTextComponent;
