import { render, staticRenderFns } from "./FinanceiroValorPagoTextComponent.vue?vue&type=template&id=13c002ee&scoped=true&"
import script from "./FinanceiroValorPagoTextComponent.vue?vue&type=script&lang=ts&"
export * from "./FinanceiroValorPagoTextComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13c002ee",
  null
  
)

export default component.exports