import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { financeiroParcelaStore, analyticsStore, userToolStore, userActionStore, } from '@/store';
import _ from 'lodash';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import FinanceiroValorPagoTextComponent from '@/components/FinanceiroValorPagoTextComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import SaveButton from '@/components/SaveButton.vue';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                show: true,
                table: 'financeiro_categoria',
                text: 'Categoria',
                type: '',
                value: 'financeiro_categoria_nome',
                field: 'nome',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Entidade - Nome',
                value: 'entidade_nome',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Entidade - Tipo',
                value: 'entidade_tipo',
            },
            {
                table: 'aluno',
                text: 'Aluno - Celular',
                value: 'aluno_celular',
            },
            {
                show: true,
                table: 'aluno',
                text: 'Telefone',
                value: 'aluno_contatos',
            },
            {
                show: true,
                table: 'aluno',
                text: 'Responsável Financeiro',
                value: 'responsavel_nome',
            },
            {
                table: 'aluno',
                text: 'Responsável - Celular',
                value: 'responsavel_celular',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Parcela',
                type: '',
                value: 'parcela',
            },
            {
                show: true,
                table: 'financeiro',
                text: 'Emissão',
                type: '',
                value: 'data_emissao',
            },
            {
                show: true,
                table: 'financeiro_parcela',
                text: 'Vencimento',
                type: '',
                value: 'vencimento',
            },
            {
                show: true,
                table: 'financeiro_parcela',
                text: 'Valor Integral',
                type: '',
                value: 'valor_integral',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Valor com desconto',
                type: '',
                value: 'valor_original',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Valor Atualizado',
                type: '',
                value: 'valor_atualizado',
            },
            {
                show: true,
                table: 'financeiro_parcela',
                text: 'Valor Pago',
                type: '',
                value: 'valor_pago',
            },
            {
                show: true,
                table: 'financeiro_parcela',
                text: 'Observação',
                type: '',
                value: 'observacao',
            },
            {
                show: true,
                table: 'financeiro_parcela_status',
                text: 'Status',
                type: '',
                value: 'financeiro_parcela_status_nome',
                field: 'nome',
            },
            {
                show: true,
                table: 'contrato',
                text: 'Status do contrato',
                type: '',
                value: 'contrato_status_id',
                field: 'status_id',
            },
            {
                show: true,
                text: 'Ações',
                value: 'actions',
            },
        ];
        this.headerVencido = [
            {
                text: 'Categoria',
                value: 'Categoria',
            },
            {
                text: 'A receber - Valor integral (Sem desconto)',
                value: 'temp_receber_valor_integral_sem_desconto',
            },
            {
                text: 'A receber - Valor com desconto',
                value: 'temp_receber_valor_com_desconto',
            },
            {
                text: 'A receber - Valor atualizado',
                value: 'temp_receber_valor_atualizado',
            },
            {
                text: 'Valor recebido',
                value: 'temp_valor_recebido',
            },
        ];
        this.excluirParcelasHeaders = [
            { text: '', value: 'checked' },
            { text: 'Número da parcela', value: 'parcela_numero' },
            { text: 'Vencimento', value: 'vencimento' },
            { text: 'Valor Integral', value: 'valor_integral', align: 'left' },
            { text: 'Valor Atualizado', value: 'valor_atualizado', align: 'left' },
            { text: 'Valor Pago', value: 'valor_pago', align: 'left' },
            { text: 'Status', value: 'status', align: 'left' },
        ];
        this.selectPeriodDate = null;
        this.excluirParcelasDialog = false;
        this.excluirParcelasOptions = [];
        this.excluirParcelaSelected = null;
    }
    get allowView() {
        if (userToolStore.userToolsIndexed['Contas a receber']) {
            return userToolStore.userToolsIndexed['Contas a receber'][0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed['Contas a receber']) {
            return userToolStore.userToolsIndexed['Contas a receber'][0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed['Contas a receber']) {
            return userToolStore.userToolsIndexed['Contas a receber'][0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed['Contas a receber']) {
            return userToolStore.userToolsIndexed['Contas a receber'][0].allow_delete;
        }
    }
    get userActionsVisualizarTotalizadoresAllow() {
        return userActionStore.visualizarTotalizadoresContasReceber;
    }
    get totals() {
        return analyticsStore.totals;
    }
    async deleteItem(item) {
        this.excluirParcelaSelected = item;
        this.excluirParcelasOptions =
            await financeiroParcelaStore.getFinanceiroParcelasByFinanceiro(item.financeiro_id);
        _.forEach(this.excluirParcelasOptions, (el) => {
            if (el.id === item.id) {
                el.checked = true;
            }
        });
        this.excluirParcelasDialog = true;
    }
    async confirmDeleteItem() {
        const itemsReq = [];
        _.forEach(this.excluirParcelasOptions, (item) => {
            if (item.checked) {
                itemsReq.push(item.id);
            }
        });
        const stringSwal = itemsReq.length > 0
            ? 'Confirma a exclusão das Contas?'
            : 'Confirma a exclusão da Conta?';
        // @ts-ignore
        this.$swal({
            title: stringSwal,
            text: 'Após exclusão as informações não podem ser recuperadas!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.excluirParcelasDialog = false;
                // @ts-ignore
                await financeiroParcelaStore.deleteConta(itemsReq);
                // @ts-ignore
                this.buscar();
            }
        });
    }
    async setPrevLucros() {
        const today = new Date();
        let year = today.getFullYear();
        let month = today.getMonth();
        if (month === 11) {
            year++;
            month = 0;
        }
        else {
            month++;
        }
        const firstDayOfNextMonth = new Date(year, month, 1);
        const lastDayOfNextMonth = new Date(year, month + 1, 0);
        const formatedFirstDate = firstDayOfNextMonth.toISOString().split('T')[0];
        const formatedLastDate = lastDayOfNextMonth.toISOString().split('T')[0];
        this.selectPeriodDate = [formatedFirstDate, formatedLastDate];
        const filters = {};
        filters['financeiro.tipo'] = {
            type: 'int',
            value: 0,
        };
        filters['tmp.vencimento'] = {
            type: 'timestamp',
            value: [this.selectPeriodDate[0], this.selectPeriodDate[1]],
        };
        analyticsStore.setDefaultFilters(filters);
        await this.doSearchTotals();
        const tempTotals = this.totals;
        // @ts-ignore
        if (tempTotals.por_financeiro_categoria_vencimento_entre) {
            // @ts-ignore
            tempTotals.por_financeiro_categoria_vencimento_entre.items.forEach((item) => {
                if (item.Categoria != 'Curso') {
                    item.selected = false;
                }
            });
        }
        // @ts-ignore
        if (tempTotals.por_status) {
            // @ts-ignore
            tempTotals.por_status.items.forEach((item) => {
                if (item.Status != 'Em aberto') {
                    item.selected = false;
                }
            });
        }
        // @ts-ignore
        if (tempTotals.por_status_contrato) {
            // @ts-ignore
            tempTotals.por_status_contrato.items.forEach((item) => {
                if (item.Status != 'Vigente') {
                    item.selected = false;
                }
            });
        }
        analyticsStore.setTotals({});
        setTimeout(() => {
            analyticsStore.setTotals(tempTotals);
            analyticsStore.executeSearchItems();
        }, 100);
    }
    async buscar() {
        const filters = {};
        filters['financeiro.tipo'] = {
            type: 'int',
            value: 0,
        };
        filters['tmp.vencimento'] = {
            type: 'timestamp',
            value: [this.selectPeriodDate[0], this.selectPeriodDate[1]],
        };
        analyticsStore.setDefaultFilters(filters);
        await this.doSearchTotals();
        await analyticsStore.executeSearchItems();
        // @ts-ignore
        await this.$refs.loadingButton.changeLoadingStatus(false);
    }
    async doSearchItems(queryIn) {
        return await financeiroParcelaStore.getFinanceiroParcelasQuery(queryIn);
    }
    async doSearchTotals() {
        await analyticsStore.getAnalyticsFinanceiroParcela({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
            tipo: 0,
            data_campo: 'financeiro_parcela.vencimento',
        });
    }
    routeEdit(id) {
        const rota = this.$router.resolve({
            name: 'main-contasareceber-edit',
            params: { id },
        });
        window.open(rota.href, '_blank');
    }
    async mounted() {
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(financeiroParcelaStore.getFinanceiroParcelasQuery);
        analyticsStore.setHeaders(this.headers);
        // tela que tem defaultFilter nao precisa ter o buscar() aqui
    }
};
__decorate([
    Prop(Boolean)
], List.prototype, "financeiroType", void 0);
List = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            EntidadeLinkComponent,
            SearchEntidadeComponent,
            FinanceiroValorPagoTextComponent,
            GenericListTotalCardComponent,
            GenericListItemsCardComponent,
            TextExpandableComponent,
            OpenNewTabComponent,
            SaveButton,
        },
    })
], List);
export default List;
